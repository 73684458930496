import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, filter, map, take } from 'rxjs';
import { ChatService } from './chat.service';

@Injectable({
  providedIn: 'root',
})
export class EnableChatGuard {
  constructor(private chatService: ChatService, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> {
    return this.chatService.showChat$.pipe(
      filter(value => value !== null), // Esperar hasta que el valor no sea null
      take(1), // Tomar solo el primer valor después de que no sea null
      map(value => {
        if (!value) {
          return this.router.parseUrl('dashboard');
        }
        return value; // Esto devolverá el valor booleano emitido por showChat$
      }),
    );
  }
}
