import { CommonModule, NgClass } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { ConfigModel, UserModel } from '@bp-core/src/lib/proto/common-message.pb';
import { PortalUserGrpcServiceClient } from '@bp-core/src/lib/proto/portal-user.pbsc';
import { UserGrpcServiceClient } from '@bp-core/src/lib/proto/user.pbsc';
import { AuthService } from '@bp-core/src/lib/services/portal/auth.service';
import { RedirectService } from '@bp-core/src/lib/services/portal/redirect.service';
import { environment } from '@environment/environment';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { GrpcCoreModule } from '@ngx-grpc/core';
import { Empty } from '@ngx-grpc/well-known-types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { BpUserComponent } from 'bp/layout/common/bp-user/bp-user.component';
import { ChatService } from 'bp/modules/chat/chat.service';
import { BpHeaderConfig } from 'bp/shared/models/header-config';
import { BpHeaderService } from 'bp/shared/services/bp-header.service';
import { Subject, combineLatest, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'bp-layout',
  templateUrl: './bp.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    FuseVerticalNavigationComponent,
    FuseLoadingBarComponent,
    MatButtonModule,
    RouterOutlet,
    MatIconModule,
    BpUserComponent,
    NgClass,
    GrpcCoreModule
],
})
export class BpLayoutComponent implements OnInit, OnDestroy {
  env = environment;
  isScreenSmall: boolean;
  navigation: Navigation;
  user: UserModel;
  fileUrlBase = environment.urlApiV2 + 'api/Files/';
  config: ConfigModel;
  token = localStorage.getItem('access_token');
  headerConfig: BpHeaderConfig | null = null;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  routerUrl: string;

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private authService: AuthService,
    private userGrpcServiceClient: UserGrpcServiceClient,
    private redirectService: RedirectService,
    private portalUserGrpcServiceClient: PortalUserGrpcServiceClient,
    private router: Router,
    private bpHeaderService: BpHeaderService,
    private chatService: ChatService,
  ) {
    bpHeaderService.headerConfig$.subscribe(config => {
      setTimeout(() => {
        this.headerConfig = config;
      }, 0);
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this.getDollarBoxIsAvalibleConfig();

    this.routerUrl = this.router.url;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(val => {
      this.routerUrl = (val as NavigationEnd).url;
    });

    combineLatest([
      this.authService.userSubject$, // user
      this.chatService.showChat$, // showChat
      this.portalUserGrpcServiceClient.getConfig(new Empty()),
    ]).subscribe(([user, showChat, config]) => {
      // console.warn([navigation, user, goals, codes, cajaMonedaConfig]);
      this.config = config;

      this._navigationService.get().subscribe(navigation => {
        if (user) this.user = user;
        let chatItem = navigation.bp.find(item => item.id === 'chat');
        if (chatItem) {
          chatItem.hidden = () => !showChat;
        }
        this.navigation = navigation;
      });
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
      name,
    );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  openIntercomChat($event: MouseEvent) {
    $event.preventDefault();
    try {
      (window as any).Intercom('show');
    } catch (error) {
      console.log(error);
    }
  }

  bpImgClick() {
    this.router.navigate(['/dashboard']);
  }

  formatFaIcon(faIcon: string) {
    let faIconClass = '';
    const faSplitIcon = faIcon.split('-');
    switch (faSplitIcon[0]) {
      case 'fab fa':
        faIconClass = 'bp-fa-icon ' + faIcon;
        break;
      case 'fa':
        faIconClass = 'bp-fa-icon fa ' + faIcon;
        break;
    }
    return faIconClass;
  }

  // Get icon or icon class of a goal for the nav
  getIconOrClass(portalIcon?: string): { icon: string; classes: string } {
    if (!portalIcon) return { icon: 'mat_outline:help_outline', classes: '' };

    let fuseIcon = {
      'fa-baby': 'mat_outline:escalator_warning',
      'fa-user-graduate': 'heroicons_outline:academic-cap',
      'fa-piggy-bank': 'mat_outline:savings',
      'fa-gift': 'heroicons_outline:gift',
      'fa-home': 'heroicons_outline:home',
      'fa-umbrella': 'feather:umbrella',
      'fa-user-tie': 'heroicons_outline:briefcase',
      'fa-heart': 'heroicons_outline:heart',
      'fa-arrow-up': 'feather:arrow-up-circle',
      'fa-umbrella-beach': 'mat_solid:beach_access',
      'fa-arrow-circle-up': 'feather:arrow-up-circle',
      'fa-users': 'heroicons_outline:user-group',
      'fa-biking': 'mat_outline:directions_bike',
      'fa-plane-departure': 'mat_outline:flight_takeoff',
      'fa-graduation-cap': 'heroicons_outline:academic-cap',
      'fa-mountain': 'mat_outline:landscape',
      'fa-university': 'heroicons_outline:building-library',
    };

    if (Object.keys(fuseIcon).includes(portalIcon)) {
      return { icon: fuseIcon[portalIcon], classes: '' };
    } else {
      // Icon always has to have content
      // this icon is goin to has d-none
      return {
        icon: 'heroicons_solid:chevron-up',
        classes: this.formatFaIcon(portalIcon) || 'fa-regular fa-circle',
      };
    }
  }
}
